<template>
  <div class="content-report">
    <div class="mq-header">
      <div class="mq-header-left">
        <img src="@/assets/img/mq-title.png" class="mq-header-img" />
        <span>康一生®持续体糖监测系统（每日生理）</span>
      </div>
      <div class="mq-header-right">{{ monitorTime.index }} ({{ monitorTime.time }})</div>
    </div>
    <div class="mq-title">
      <div class="mq-title-item">
        <span>姓名</span>
        <span>{{ userInfo.name }}</span>
      </div>
      <div class="mq-title-item">
        <span>性别</span>
        <span>{{ userInfo.sex_show }}</span>
      </div>
      <div class="mq-title-item">
        <span>年龄</span>
        <span>{{ userInfo.age }}</span>
      </div>
      <div class="mq-title-item">
        <span>身高</span>
        <span>{{ userInfo.sg }}</span>
      </div>
      <div class="mq-title-item">
        <span>体重</span>
        <span>{{ userInfo.tz }}</span>
      </div>
      <div class="mq-title-item">
        <span>BMI</span>
        <span>{{ userInfo.bmi }}</span>
      </div>
    </div>
    <div class="mq-sub-title">
      <img src="@/assets/img/mq-sub-title.png" class="mq-sub-img" />
      24 时动态体糖曲线
    </div>
    <div class="chart-panel">
      <div class="chart-header">
        <div class="chart-header-item">距离正常期时间差值：{{data.qx_data.jlaqsjcz}}</div>
        <div class="chart-header-item grey">代谢紊乱（结构受损）时长：{{data.qx_data.sjtj.jgss}}</div>
        <div class="chart-header-item red">代谢紊乱（功能受损）时长：{{data.qx_data.sjtj.ngss}}</div>
        <div class="chart-header-item green">正常期时长：{{data.qx_data.sjtj.zcq}}</div>
        <div class="chart-header-item yellow">调节受损期时长：{{data.qx_data.sjtj.tjss}}</div>
      </div>
      <div class="chart-holder" ref="echart"></div>
      <div class="chart-footer">
        <div class="chart-header-item">
          MAX: {{data.qx_data.area_pjz.area_0_6.max}}
          MIN: {{data.qx_data.area_pjz.area_0_6.min}}
          AVG: {{data.qx_data.area_pjz.area_0_6.pjz}}
        </div>
        <div class="chart-header-item">
          MAX: {{data.qx_data.area_pjz.area_6_12.max}}
          MIN: {{data.qx_data.area_pjz.area_6_12.min}}
          AVG: {{data.qx_data.area_pjz.area_6_12.pjz}}
        </div>
        <div class="chart-header-item">
          MAX: {{data.qx_data.area_pjz.area_12_18.max}}
          MIN: {{data.qx_data.area_pjz.area_12_18.min}}
          AVG: {{data.qx_data.area_pjz.area_12_18.pjz}}
        </div>
        <div class="chart-header-item">
          MAX: {{data.qx_data.area_pjz.area_18_24.max}}
          MIN: {{data.qx_data.area_pjz.area_18_24.min}}
          AVG: {{data.qx_data.area_pjz.area_18_24.pjz}}
        </div>
      </div>
    </div>
    <div class="mq-sub-title--border">体糖监测情况</div>
    <div class="mq-summary">
      <div class="mq-summary-item">
        <div class="mq-summary-item-title">单日平均体糖</div>
        <div class="mq-summary-item-value">
          <span>{{ summaryData.drpjxt }}</span>
          <span>mmol/L</span>
        </div>
      </div>
      <div class="mq-summary-item">
        <div class="mq-summary-item-title">单日最高体糖 {{summaryData.highBlood_time}}</div>
        <div class="mq-summary-item-value">
          <span>{{ summaryData.drzgxt }}</span>
          <span>mmol/L</span>
        </div>
      </div>
      <div class="mq-summary-item">
        <div class="mq-summary-item-title">单日最低体糖 {{summaryData.lowBlood_time}}</div>
        <div class="mq-summary-item-value">
          <span>{{ summaryData.drzdxt }}</span>
          <span>mmol/L</span>
        </div>
      </div>
      <div class="mq-summary-item">
        <div class="mq-summary-item-title">单日体糖波动</div>
        <div class="mq-summary-item-value">
          <span>{{ summaryData.drxtbd }}</span>
          <span>mmol/L</span>
        </div>
      </div>
    </div>
    <div class="mq-result">
      <div class="mq-result-chart">
        <div style="height: 320px;" ref="pieChart"></div>
        <div class="mq-result-text">
          <p>{{ result.jgms1 }}</p>
          <p>{{ result.jgms2 }}</p>
        </div>
      </div>
      <div class="mq-result-body">
        <div class="mq-result-body_title">代谢性慢性疾病风险</div>
        <div class="mq-result-body_content">
          <img src="@/assets/img/mq-body.jpg" />
          <div class="mq-content-item mq-content-item_eye">
            <div class="mq-item--content">
              <div>眼部风险</div>
              <div>{{ bodyData.yb }} %</div>
            </div>
            <div class="mq-item--line"></div>
          </div>
          <div class="mq-content-item mq-content-item_shenzang">
            <div class="mq-item--content">
              <div>肾脏风险</div>
              <div>{{ bodyData.sz }} %</div>
            </div>
            <div class="mq-item--line"></div>
          </div>
          <div class="mq-content-item mq-content-item_shenjing">
            <div class="mq-item--content">
              <div>神经风险</div>
              <div>{{ bodyData.sj }} %</div>
            </div>
            <div class="mq-item--line"></div>
          </div>
          <div class="mq-content-item mq-content-item_foot">
            <div class="mq-item--content">
              <div>糖尿病足风险</div>
              <div>{{ bodyData.tnbz }} %</div>
            </div>
            <div class="mq-item--line"></div>
          </div>
          <div class="mq-content-item mq-content-item_brain">
            <div class="mq-item--line"></div>
            <div class="mq-item--content">
              <div>脑部风险</div>
              <div>{{ bodyData.nb }} %</div>
            </div>
          </div>
          <div class="mq-content-item mq-content-item_heart">
            <div class="mq-item--line"></div>
            <div class="mq-item--content">
              <div>心脏风险</div>
              <div>{{ bodyData.xz }} %</div>
            </div>
          </div>
          <div class="mq-content-item mq-content-item_skin">
            <div class="mq-item--line"></div>
            <div class="mq-item--content">
              <div>皮肤风险</div>
              <div>{{ bodyData.pf }} %</div>
            </div>
          </div>
          <div class="mq-content-item mq-content-item_xueguan">
            <div class="mq-item--line"></div>
            <div class="mq-item--content">
              <div>血管风险</div>
              <div>{{ bodyData.xg }} %</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import echarts from 'echarts';

export default {
	props: {
		userInfo: {
			default: () => {},
		},
		data: {
			default: () => {},
		},
	},
	data() {
		return {
			monitorTime: {},
			summaryData: {},
			bodyData: {},
			result: {},
		};
	},
	mounted() {
		this.createLineChart(this.data.qx_data.qxsj);
		this.createPieChart(this.data.jg_data.xtzbsj);
		this.summaryData = this.data.jg_data.xtjcqktj;
		this.bodyData = this.data.jg_data.bfzfxfx;
		this.result = this.data.jg_data.jgms;
		this.monitorTime = {
			index: this.data.qx_data.day,
			time: this.data.qx_data.rq,
		};
	},
	methods: {
		createLineChart(data) {
			const today = data[0].recordDate.slice(0, 10);
			const chartData = [];
			const barChartData = [];
			const barChartHolderData = [];
			const markPointData = [];
			for (let i = 0, len = data.length; i < len; i++) {
				const item = data[i];
				if (item.machineBloodSugar > 0) {
					chartData.push([item.recordDate, item.machineBloodSugar]);
				}
				if (item.eventList.length > 0) {
					for (let j = 0, l = item.eventList.length; j < l; j++) {
						const eventType = item.eventList[j].eventType;
						if (eventType === 1) {
							const value = [item.recordDate, item.machineBloodSugar];
							markPointData.push({
								coord: value,
								symbol: 'image://' + new URL('/src/assets/img/canbi.png', import.meta.url).href,
								symbolSize: 18,
							});
						} else if (eventType === 2) {
							const value = [item.recordDate, item.machineBloodSugar];
							markPointData.push({
								coord: value,
								symbol: 'image://' + new URL('/src/assets/img/yinshi.png', import.meta.url).href,
								symbolSize: 18,
							});
						} else if (eventType === 3) {
							const value = [item.recordDate, item.machineBloodSugar];
							markPointData.push({
								coord: value,
								symbol: 'image://' + new URL('/src/assets/img/yongyao.png', import.meta.url).href,
								symbolSize: 18,
							});
						} else if (eventType === 5) {
							const value = [item.recordDate, item.machineBloodSugar];
							markPointData.push({
								coord: value,
								symbol: 'image://' + new URL('/src/assets/img/duanlian.png', import.meta.url).href,
								symbolSize: 18,
							});
						}
					}
				}
				barChartData.push([
					item.recordDate,
					Math.abs(item.machineBloodSugar - 5),
					item.machineBloodSugar,
				]);
				barChartHolderData.push([
					item.recordDate,
					item.machineBloodSugar - 5 >= 0 ? 5 : item.machineBloodSugar,
				]);
			}
			const xAxisMarkLine = new Array(4).fill(null).map((item, index) => {
				return {
					xAxis: `${today} ${6 * (index + 1)}:00`,
					lineStyle: { color: '#ff7171', width: 2 },
				};
			});
			echarts.init(this.$refs.echart).setOption({
				grid: {
					top: 40,
					left: 25,
					right: 25,
					bottom: 40,
				},
				animation: false,
				xAxis: {
					type: 'time',
					axisTick: { show: false },
					axisLine: {
						lineStyle: {
							color: '#ff7171',
						},
					},
					splitLine: false,
					interval: 60 * 60 * 1000,
				},
				yAxis: {
					name: 'mmol/L',
					type: 'value',
					splitLine: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#ff7171',
						},
					},
					min: 2,
				},
				series: [
					{
						name: '体糖',
						type: 'line',
						data: chartData,
						symbol: 'none',
						itemStyle: {
							normal: {
								lineStyle: {
									color: '#0f85f3',
								},
							},
						},
						markPoint: {
							symbolSize: 30,
							data: markPointData,
						},
						markLine: {
							label: { show: false },
							symbol: 'none',
							itemStyle: { color: '#999' },
							data: [
								{
									yAxis: 3.0,
								},
								{
									yAxis: 3.9,
								},
								{
									yAxis: 4.4,
								},
								{
									yAxis: 6.1,
								},
								{
									yAxis: 7.0,
								},
								{
									yAxis: 7.8,
								},
								{
									yAxis: 11.1,
								},
								{
									yAxis: 5,
									lineStyle: { color: '#47e1b9', type: 'solid' },
								},
								...xAxisMarkLine,
							],
							silent: true,
						},
					},
					{
						name: '透明',
						stack: '总量',
						type: 'bar',
						data: barChartHolderData,
						itemStyle: {
							color: 'rgba(0,0,0,0)',
						},
					},
					{
						name: '结果',
						stack: '总量',
						type: 'bar',
						data: barChartData,
						itemStyle: {
							color: function (params) {
								const value = params.value[2];
								if (value < 4.4) {
									return '#f34141';
								} else if (value < 6.1) {
									return '#3ea40c';
								} else if (value < 7.8) {
									return '#fab02a';
								} else if (value < 11.1) {
									return '#f34141';
								} else {
									return '#383737';
								}
							},
							barBorderRadius: 20,
						},
					},
				],
			});
		},
		createPieChart(data) {
			echarts.init(this.$refs.pieChart).setOption({
				color: ['#3ea40c', '#fab02a', '#f34141', '#383737'],
				series: [
					{
						name: '体糖占比',
						type: 'pie',
						radius: '60%',
						data: [
							{ value: data.zc_xt, name: '正常期' },
							{ value: data.pg_xt, name: '调节受损期' },
							{ value: +data.d_xt + +data.g_xt, name: '代谢紊乱\n(功能受损)' },
							{ value: data.jg_xt, name: '代谢紊乱\n(结构受损)' },
						],
						label: {
							formatter: (params) => {
								return `${params.name}\n${params.percent} %`;
							},
							fontSize: 14,
						},
						animation: false,
					},
				],
			});
		},
	},
};
</script>

<style lang="less" scoped>
.content-report {
  width: 1100px;
  margin: 0 auto;
  padding: 20px;
}
.mq-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #c63535;
  font-size: 26px;
  padding-bottom: 20px;
  border-bottom: 2px solid #c63535;
  .mq-header-left {
    font-weight: bold;
    .mq-header-img {
      height: 38px;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      color: #fff;
      background-color: #c63535;
      height: 38px;
      line-height: 38px;
      margin-left: 10px;
      vertical-align: middle;
      padding: 0 10px;
    }
  }
}
.mq-title {
  display: flex;
  justify-content: space-around;
  color: #c63535;
  font-size: 22px;
  margin: 20px 0 30px;
  .mq-title-item {
    span:last-child {
      display: inline-block;
      width: 100px;
      border-bottom: 1px solid #c63535;
      text-align: center;
      margin-left: 10px;
      padding-bottom: 5px;
    }
  }
}
.mq-sub-title {
  color: #c63535;
  font-size: 24px;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  img {
    height: 40px;
    margin-right: 10px;
  }
}
.mq-sub-title--border {
  color: #c63535;
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 5px;
  display: inline-block;
  border-bottom: 1px solid #c63535;
}
.mq-summary {
  display: flex;
  justify-content: space-around;
  font-size: 22px;
  margin: 20px 0;
  &-item {
    color: #c63535;
    border: 1px solid #c63535;
    border-radius: 4px;
    padding: 10px 20px;
    span:first-child {
      font-size: 28px;
      font-weight: bold;
      margin-right: 5px;
    }
  }
}
.mq-result {
  display: flex;
  margin-top: 50px;
  .mq-result-chart {
    flex: 1;
    margin-right: 20px;
  }
  .mq-result-text {
    margin-top: 30px;
    color: #c63535;
    font-size: 22px;
    padding: 10px 20px;
    border: 1px solid #ecd8d4;
    border-radius: 4px;
    p {
      line-height: 1.5;
      margin-bottom: 20px;
    }
  }
  .mq-result-body {
    text-align: center;
    flex: 1;
  }
  .mq-result-body_title {
    color: #fff;
    background-color: #c63535;
    font-size: 22px;
    font-weight: bold;
    padding: 5px 10px;
    display: inline-block;
  }
  .mq-result-body_content {
    position: relative;
    img {
      height: 600px;
    }
    .mq-content-item {
      color: #c63535;
      font-size: 24px;
      display: flex;
      align-items: center;
      position: absolute;
      &_eye {
        top: 18px;
        left: 98px;
      }
      &_shenzang {
        top: 200px;
        left: 80px;
      }
      &_shenjing {
        top: 280px;
        left: 5px;
      }
      &_foot {
        top: 550px;
        left: 30px;
      }
      &_brain {
        top: 6px;
        right: 100px;
      }
      &_heart {
        top: 150px;
        right: 85px;
      }
      &_skin {
        top: 380px;
        right: 65px;
      }
      &_xueguan {
        top: 470px;
        right: 82px;
      }
      .mq-item--line {
        height: 2px;
        background-color: #c63535;
        width: 60px;
        margin: 0 5px;
      }
    }
    .mq-item--content {
      color: #c63535;
      font-size: 20px;
      div:last-child {
        font-weight: bold;
      }
    }
  }
}
.chart-panel {
  margin: 40px 0;
  .chart-header {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
  }
  .chart-holder {
    height: 260px;
  }
  .chart-footer {
    display: flex;
    font-weight: bold;
    margin: 0 25px;
    justify-content: space-around;
  }
  .grey {
    color: #383737;
  }
  .red {
    color: #F34141;
  }
  .green {
    color: #3ea40c;
  }
  .yellow {
    color: #fab02a;
  }
}
</style>
