<template>
  <div class="content-report-panel">
    <div class="content-report">
      <div class="mq-header">
        <div class="mq-header-left">
          <img src="@/assets/img/mq-title.png" class="mq-header-img" />
          <span>康一生®持续体糖监测系统（生理）</span>
        </div>
        <div class="mq-header-right">7 天生理一 ({{ monitorTime }})</div>
      </div>
      <div class="mq-title">
        <div class="mq-title-item">
          <span>姓名</span>
          <span>{{ userInfo.name }}</span>
        </div>
        <div class="mq-title-item">
          <span>性别</span>
          <span>{{ userInfo.sex_show }}</span>
        </div>
        <div class="mq-title-item">
          <span>年龄</span>
          <span>{{ userInfo.age }}</span>
        </div>
        <div class="mq-title-item">
          <span>身高</span>
          <span>{{ userInfo.sg }}</span>
        </div>
        <div class="mq-title-item">
          <span>体重</span>
          <span>{{ userInfo.tz }}</span>
        </div>
        <div class="mq-title-item">
          <span>BMI</span>
          <span>{{ userInfo.bmi }}</span>
        </div>
      </div>
      <div class="mq-sub-title">
        <img src="@/assets/img/mq-sub-title.png" class="mq-sub-img" />
        24 时动态体糖曲线
      </div>
      <div class="chart-holder" ref="echart"></div>
      <div class="mq-sub-title--border">体糖监测情况统计</div>
      <div class="mq-chart-flex">
        <div class="mq-chart-flex-left">
          <div class="mq-summary-item">
            <div class="mq-summary-item-title">7 日平均体糖</div>
            <div class="mq-summary-item-value">
              <span>{{ xtjcqktj.pjxt }}</span>
              <span>mmol/L</span>
            </div>
          </div>
        </div>
        <div class="mq-chart-flex-right">
          <div class="chart-holder-narrow" ref="echartAvg"></div>
        </div>
      </div>
      <div class="mq-chart-flex">
        <div class="mq-chart-flex-left">
          <div class="mq-summary-item">
            <div class="mq-summary-item-title">7 日最高体糖</div>
            <div class="mq-summary-item-value">
              <span>{{ xtjcqktj.zgxt }}</span>
              <span>mmol/L</span>
            </div>
          </div>
        </div>
        <div class="mq-chart-flex-right">
          <div class="chart-holder-narrow" ref="echartHigh"></div>
        </div>
      </div>
      <div class="mq-chart-flex">
        <div class="mq-chart-flex-left">
          <div class="mq-summary-item">
            <div class="mq-summary-item-title">7 日最低体糖</div>
            <div class="mq-summary-item-value">
              <span>{{ xtjcqktj.zdxt }}</span>
              <span>mmol/L</span>
            </div>
          </div>
        </div>
        <div class="mq-chart-flex-right">
          <div class="chart-holder-narrow" ref="echartLow"></div>
        </div>
      </div>
      <div class="mq-chart-flex">
        <div class="mq-chart-flex-left">
          <div class="mq-summary-item">
            <div class="mq-summary-item-title">7 日体糖波动</div>
            <div class="mq-summary-item-value">
              <span>{{ xtjcqktj.xtbd }}</span>
              <span>mmol/L</span>
            </div>
          </div>
        </div>
        <div class="mq-chart-flex-right">
          <div class="chart-holder-narrow" ref="echartWave"></div>
        </div>
      </div>
    </div>
    <div class="content-report">
      <div class="mq-header">
        <div class="mq-header-left">
          <img src="@/assets/img/mq-title.png" class="mq-header-img" />
          <span>康一生®持续体糖监测系统（生理）</span>
        </div>
        <div class="mq-header-right">7 天生理二 ({{ monitorTime }})</div>
      </div>
      <div class="mq-title">
        <div class="mq-title-item">
          <span>姓名</span>
          <span>{{ userInfo.name }}</span>
        </div>
        <div class="mq-title-item">
          <span>性别</span>
          <span>{{ userInfo.sex_show }}</span>
        </div>
        <div class="mq-title-item">
          <span>年龄</span>
          <span>{{ userInfo.age }}</span>
        </div>
        <div class="mq-title-item">
          <span>身高</span>
          <span>{{ userInfo.sg }}</span>
        </div>
        <div class="mq-title-item">
          <span>体重</span>
          <span>{{ userInfo.tz }}</span>
        </div>
        <div class="mq-title-item">
          <span>BMI</span>
          <span>{{ userInfo.bmi }}</span>
        </div>
      </div>
      <div class="mq-sub-title">
        <img src="@/assets/img/mq-title-logo.png" class="mq-sub-img" />
        体糖时间占比堆积图
      </div>
      <div class="chart-holder" ref="echartStack"></div>
      <div class="mq-sub-title--border">NESW 四段体糖平均值</div>
      <div class="mq-chart-flex">
        <div class="mq-chart-flex-left">
          <div class="mq-label-item">
            <img src="@/assets/img/n06.png">
          </div>
        </div>
        <div class="mq-chart-flex-right mq-chart-flex-right--border">
          <div class="chart-holder-narrow" ref="echartAvg0"></div>
        </div>
      </div>
      <div class="mq-chart-flex">
        <div class="mq-chart-flex-left">
          <div class="mq-label-item">
            <img src="@/assets/img/n612.png">
          </div>
        </div>
        <div class="mq-chart-flex-right mq-chart-flex-right--border">
          <div class="chart-holder-narrow" ref="echartAvg6"></div>
        </div>
      </div>
      <div class="mq-chart-flex">
        <div class="mq-chart-flex-left">
          <div class="mq-label-item">
            <img src="@/assets/img/n1218.png">
          </div>
        </div>
        <div class="mq-chart-flex-right mq-chart-flex-right--border">
          <div class="chart-holder-narrow" ref="echartAvg12"></div>
        </div>
      </div>
      <div class="mq-chart-flex">
        <div class="mq-chart-flex-left">
          <div class="mq-label-item">
            <img src="@/assets/img/n1824.png">
          </div>
        </div>
        <div class="mq-chart-flex-right mq-chart-flex-right--border">
          <div class="chart-holder-narrow" ref="echartAvg18"></div>
        </div>
      </div>
    </div>
    <div class="content-report">
      <div class="mq-header">
        <div class="mq-header-left">
          <img src="@/assets/img/mq-title.png" class="mq-header-img" />
          <span>康一生®持续体糖监测系统（生理）</span>
        </div>
        <div class="mq-header-right">7 天生理三 ({{ monitorTime }})</div>
      </div>
      <div class="mq-title">
        <div class="mq-title-item">
          <span>姓名</span>
          <span>{{ userInfo.name }}</span>
        </div>
        <div class="mq-title-item">
          <span>性别</span>
          <span>{{ userInfo.sex_show }}</span>
        </div>
        <div class="mq-title-item">
          <span>年龄</span>
          <span>{{ userInfo.age }}</span>
        </div>
        <div class="mq-title-item">
          <span>身高</span>
          <span>{{ userInfo.sg }}</span>
        </div>
        <div class="mq-title-item">
          <span>体重</span>
          <span>{{ userInfo.tz }}</span>
        </div>
        <div class="mq-title-item">
          <span>BMI</span>
          <span>{{ userInfo.bmi }}</span>
        </div>
      </div>
      <div class="mq-result">
        <div class="mq-result-chart">
        <div class="mq-result-body_title">7 日体糖范围分布</div>
        <div style="height: 320px;margin-top:20px;" ref="pieChart"></div>
        <div class="mq-result-text">
          <p>{{ xtfwfb.jgms1 }}</p>
          <p>{{ xtfwfb.jgms2 }}</p>
        </div>
        </div>
        <div class="mq-result-body">
          <div class="mq-result-body_title">代谢性慢性疾病风险</div>
          <div class="mq-result-body_content">
            <img src="@/assets/img/mq-body.jpg" />
            <div class="mq-content-item mq-content-item_eye">
              <div class="mq-item--content">
                <div>眼部风险</div>
                <div>{{ bodyData.yb }} %</div>
              </div>
              <div class="mq-item--line"></div>
            </div>
            <div class="mq-content-item mq-content-item_shenzang">
              <div class="mq-item--content">
                <div>肾脏风险</div>
                <div>{{ bodyData.sz }} %</div>
              </div>
              <div class="mq-item--line"></div>
            </div>
            <div class="mq-content-item mq-content-item_shenjing">
              <div class="mq-item--content">
                <div>神经风险</div>
                <div>{{ bodyData.sj }} %</div>
              </div>
              <div class="mq-item--line"></div>
            </div>
            <div class="mq-content-item mq-content-item_foot">
              <div class="mq-item--content">
                <div>糖尿病足风险</div>
                <div>{{ bodyData.tnbz }} %</div>
              </div>
              <div class="mq-item--line"></div>
            </div>
            <div class="mq-content-item mq-content-item_brain">
              <div class="mq-item--line"></div>
              <div class="mq-item--content">
                <div>脑部风险</div>
                <div>{{ bodyData.nb }} %</div>
              </div>
            </div>
            <div class="mq-content-item mq-content-item_heart">
              <div class="mq-item--line"></div>
              <div class="mq-item--content">
                <div>心脏风险</div>
                <div>{{ bodyData.xz }} %</div>
              </div>
            </div>
            <div class="mq-content-item mq-content-item_skin">
              <div class="mq-item--line"></div>
              <div class="mq-item--content">
                <div>皮肤风险</div>
                <div>{{ bodyData.pf }} %</div>
              </div>
            </div>
            <div class="mq-content-item mq-content-item_xueguan">
              <div class="mq-item--line"></div>
              <div class="mq-item--content">
                <div>血管风险</div>
                <div>{{ bodyData.xg }} %</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mq-sub-title--border">药物使用情况</div>
      <div class="mq-section-title">
        <img src="@/assets/img/mq-circle.png" />
        <span style="color: #043492;font-size: 20px;">药物服用时间</span>
      </div>
      <div class="mq-chart-panel">
        <div style="height: 240px;" ref="echartDrug"></div>
      </div>
      <div class="mq-section-title">
        <img src="@/assets/img/mq-run.png" />
        <span style="color: #178a0a;font-size: 20px;">运动时间</span>
      </div>
      <div class="mq-chart-panel">
        <div style="height: 240px;" ref="echartRun"></div>
      </div>
    </div>
    <div class="content-report" v-if="Object.values(behavior).length > 0">
      <div class="mq-header">
        <div class="mq-header-left">
          <img src="@/assets/img/mq-title.png" class="mq-header-img" />
          <span>康一生®持续体糖监测系统（行为）</span>
        </div>
        <div class="mq-header-right">7 天行为一 ({{ monitorTime }})</div>
      </div>
      <div class="mq-title">
        <div class="mq-title-item">
          <span>姓名</span>
          <span>{{ userInfo.name }}</span>
        </div>
        <div class="mq-title-item">
          <span>性别</span>
          <span>{{ userInfo.sex_show }}</span>
        </div>
        <div class="mq-title-item">
          <span>年龄</span>
          <span>{{ userInfo.age }}</span>
        </div>
        <div class="mq-title-item">
          <span>身高</span>
          <span>{{ userInfo.sg }}</span>
        </div>
        <div class="mq-title-item">
          <span>体重</span>
          <span>{{ userInfo.tz }}</span>
        </div>
        <div class="mq-title-item">
          <span>BMI</span>
          <span>{{ userInfo.bmi }}</span>
        </div>
      </div>
      <div class="mq-sub-title">
        <img src="@/assets/img/mq-sub-title.png" class="mq-sub-img" />
        7 天行为评价
      </div>
      <div class="mq-seven-desc">
        健康需要五个维度，认知、方法、工具、动作、环境五个方面缺一不可。良好的环境是健康的基础，根据您 7 天的行为数据进行汇总，对您每天的执行力进行综合评价，如下：
      </div>
      <div class="mq-seven-flex">
        <div class="mq-seven-flex-section">
          <div class="mq-seven-flex-item_one">
            参比
            <img src="@/assets/img/mq-seven-cb.png">
          </div>
          <div class="mq-seven-flex-item_two">
            <span>{{behavior.cb}}</span>次
          </div>
          <div class="mq-seven-flex-item_three">标准录入 7 次</div>
        </div>
        <div class="mq-seven-flex-section">
          <div class="mq-seven-flex-item_one">
            用药
            <img src="@/assets/img/mq-seven-yy.png">
          </div>
          <div class="mq-seven-flex-item_two">
            <span>{{behavior.yy}}</span>次
          </div>
          <div class="mq-seven-flex-item_three">标准录入 7 次</div>
        </div>
        <div class="mq-seven-flex-section">
          <div class="mq-seven-flex-item_one">
            运动
            <img src="@/assets/img/mq-seven-yd.png">
          </div>
          <div class="mq-seven-flex-item_two">
            <span>{{behavior.yd}}</span>次
          </div>
          <div class="mq-seven-flex-item_three">标准录入 7 次</div>
        </div>
        <div class="mq-seven-flex-section">
          <div class="mq-seven-flex-item_one">
            进店
            <img src="@/assets/img/mq-seven-jd.png">
          </div>
          <div class="mq-seven-flex-item_two">
            <span>{{behavior.jd}}</span>次
          </div>
          <div class="mq-seven-flex-item_three">标准录入 7 次</div>
        </div>
      </div>
      <div class="mq-seven-flex">
        <div style="width: 500px;height: 500px;" ref="echartRadar"></div>
        <div class="mq-seven-result">
          <div class="mq-seven-result_title">结果</div>
          <div class="mq-seven-result_result">
            <p>您 7 日的行为依从性为</p>
            <p class="mq-seven-result_value">{{behavior.jg}} %</p>
          </div>
        </div>
      </div>
      <div class="mq-sub-title--border">行为评价</div>
      <div class="mq-serven-comment">{{behavior.xwpj}}</div>
    </div>
    <template v-if="Object.values(food).length">
      <div
        class="content-report"
        v-for="(dayFood, index) in food.lb"
        :key="index"
      >
        <div class="mq-header">
          <div class="mq-header-left">
            <img src="@/assets/img/mq-title.png" class="mq-header-img" />
            <span>康一生®持续体糖监测系统（行为）</span>
          </div>
          <div class="mq-header-right">7 天行为二 ({{ monitorTime }})</div>
        </div>
        <div class="mq-title">
          <div class="mq-title-item">
            <span>姓名</span>
            <span>{{ userInfo.name }}</span>
          </div>
          <div class="mq-title-item">
            <span>性别</span>
            <span>{{ userInfo.sex_show }}</span>
          </div>
          <div class="mq-title-item">
            <span>年龄</span>
            <span>{{ userInfo.age }}</span>
          </div>
          <div class="mq-title-item">
            <span>身高</span>
            <span>{{ userInfo.sg }}</span>
          </div>
          <div class="mq-title-item">
            <span>体重</span>
            <span>{{ userInfo.tz }}</span>
          </div>
          <div class="mq-title-item">
            <span>BMI</span>
            <span>{{ userInfo.bmi }}</span>
          </div>
        </div>
        <div class="mq-seven-desc">
          根据您的个性化年龄、性别、身高、体重、疾病情况，通过康一生营养运动量化差值管理报告计算，推荐您每天摄入 {{food.tjsrl}} Kcal，其中碳水化合物 {{food.tshhw}} g，脂肪 {{food.zf}} g，蛋白质 {{food.dbz}} g。
        </div>
        <div class="mq-sub-title">
          <img src="@/assets/img/mq-sub-title.png" class="mq-sub-img" />
          食物耐受性（{{dayFood[0].cc}}）
        </div>
        <div class="mq-serven-comment">本测试反应的是您的机体对各种能量型食物的利用和消耗能力，体糖在组织液内的浓度正常情况下是稳定在 4.4-6.1 的范围。体糖值高于 6.1 每增高 0.5 每增加 1 小时，对血管的损伤风险增大 5%，对靶器官损伤风险增加 3%。下表分析餐后两小时内体糖数据。</div>
        <div v-for="(item, i) in dayFood" :key="i">
          <div class="mq-seven-desc">
            <span>{{item.lb_page.cc}}</span>
            <span>{{item.lb_page.sw}}</span>
          </div>
          <div class="mq-seven-food">
            <table class="mq-seven-food-table">
              <tr>
                <th>日期</th>
                <th style="width: 250px;">测试餐</th>
                <th>GL 值</th>
                <th>体糖波动（mmol/L）</th>
                <th>波动时间（min）</th>
                <th>高于 6.1 时长（min）</th>
              </tr>
              <tr v-for="row in item.lb_page.lb" :key="row.rq">
                <td>{{row.rq}}</td>
                <td>{{row.csc}}（{{item.lb_page.sw}}）</td>
                <td>{{row.gl}}</td>
                <td>{{row.ttbd}}</td>
                <td>{{row.bdsj}}</td>
                <td>{{row.gy61sc}}</td>
              </tr>
            </table>
            <div class="mq-seven-food-desc">
              根据您的对比测试结果表明，您每餐对本食物的安全食用范围为 {{item.lb_page.aqsjfw}} g。
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>  
</template>

<script type="text/ecmascript-6">
import echarts from 'echarts';

export default {
	props: {
		userInfo: {
			default: () => {},
		},
		data: {
			default: () => {},
		},
		behavior: {
			default: () => {},
		},
		food: {
			default: () => {},
		},
	},
	data() {
		return {
			monitorTime: '',
			xtjcqktj: {},
			dayList: [],
			xtfwfb: {},
			bodyData: {},
		};
	},
	mounted() {
		this.dayList = this.data.day_list;
		this.monitorTime = `${this.dayList[0]}至${this.dayList[this.dayList.length - 1]}`;
		this.xtjcqktj = this.data.xtjcqktj;
		this.xtfwfb = this.data.xtfwfb;
		this.bodyData = this.data.bfzfxfx;
		this.createLineChart(this.data.qx_data);
		this.createPieChart(this.xtfwfb.fbt);
		const refs = this.$refs;
		this.createMonitorChart(refs.echartAvg, '每日平均体糖', this.data.avgSg_list);
		this.createMonitorChart(
			refs.echartHigh,
			'每日最高体糖',
			this.data.highBlood_list,
			this.data.highBlood_time_list,
		);
		this.createMonitorChart(
			refs.echartLow,
			'每日最低体糖',
			this.data.lowBlood_list,
			this.data.lowBlood_time_list,
		);
		this.createMonitorChart(refs.echartWave, '每日体糖波动', this.data.xtbd_list);
		this.createSugarStackChart(this.data.xtsjdbdjt);
		this.createEmptyStomachSugarChart(refs.echartAvg0, this.data.area_0_6_pjz);
		this.createEmptyStomachSugarChart(refs.echartAvg6, this.data.area_6_12_pjz);
		this.createEmptyStomachSugarChart(refs.echartAvg12, this.data.area_12_18_pjz);
		this.createEmptyStomachSugarChart(refs.echartAvg18, this.data.area_18_24_pjz);
		this.createScatterChart(refs.echartDrug, this.data.yongyao, 'drug');
		this.createScatterChart(refs.echartRun, this.data.yundong, 'rect');
		setTimeout(() => {
			this.createRadarChart(refs.echartRadar, this.behavior.tb);
		}, 5000);
	},
	methods: {
		createLineChart(data) {
			const chartList = data.map((d, i) => {
				const chartData = [];
				const markPointData = [];
				for (let i = 0, len = d.length; i < len; i++) {
					const item = d[i];
					const recordDate = data[0][i].recordDate.substr(11);
					if (item.machineBloodSugar > 0) {
						chartData.push([recordDate, item.machineBloodSugar]);
					}
					if (item.eventList.length > 0) {
						for (let j = 0, l = item.eventList.length; j < l; j++) {
							const eventType = item.eventList[j].eventType;
							if (eventType === 1) {
								const value = [recordDate, item.machineBloodSugar];
								markPointData.push({
									coord: value,
									symbol: 'image://' + new URL('/src/assets/img/canbi.png', import.meta.url).href,
									symbolSize: 18,
								});
							} else if (eventType === 2) {
								const value = [recordDate, item.machineBloodSugar];
								markPointData.push({
									coord: value,
									symbol: 'image://' + new URL('/src/assets/img/yinshi.png', import.meta.url).href,
									symbolSize: 18,
								});
							} else if (eventType === 3) {
								const value = [recordDate, item.machineBloodSugar];
								markPointData.push({
									coord: value,
									symbol: 'image://' + new URL('/src/assets/img/yongyao.png', import.meta.url).href,
									symbolSize: 18,
								});
							} else if (eventType === 5) {
								const value = [recordDate, item.machineBloodSugar];
								markPointData.push({
									coord: value,
									symbol:
										'image://' + new URL('/src/assets/img/duanlian.png', import.meta.url).href,
									symbolSize: 18,
								});
							}
						}
					}
				}
				return {
					name: this.dayList[i],
					type: 'line',
					data: chartData,
					symbol: 'none',
					markPoint: {
						symbolSize: 30,
						data: markPointData,
					},
				};
			});
			(chartList[0].markArea = {
				data: [
					[
						{
							yAxis: 0,
							itemStyle: { color: '#fdcbcb' },
						},
						{
							yAxis: 4.4,
						},
					],
					[
						{
							yAxis: 4.4,
							itemStyle: { color: '#baeeb8' },
						},
						{
							yAxis: 6.1,
						},
					],
					[
						{
							yAxis: 6.1,
							itemStyle: { color: '#fdca9d' },
						},
						{
							yAxis: 7.8,
						},
					],
					[
						{
							yAxis: 7.8,
							itemStyle: { color: '#ff9e9e' },
						},
						{
							yAxis: 11.1,
						},
					],
					[
						{
							yAxis: 11.1,
							itemStyle: { color: '#b2b2b2' },
						},
						{
							yAxis: Infinity,
						},
					],
				],
				silent: true,
			}),
				echarts.init(this.$refs.echart).setOption({
					color: ['#9f0000', '#ce4d02', '#ffa91b', '#0c790c', '#00b090', '#0045b6', '#b301b0'],
					legend: {
						show: true,
					},
					grid: {
						top: 40,
						left: 25,
						right: 25,
						bottom: 40,
					},
					animation: false,
					xAxis: {
						type: 'category',
						axisTick: {
							show: false,
						},
						axisLine: {
							lineStyle: {
								color: '#999',
							},
						},
						splitLine: false,
					},
					yAxis: {
						name: 'mmol/L',
						type: 'value',
						axisTick: {
							show: true,
							interval: 5,
						},
						minorTick: {
							show: true,
							splitNumber: 2,
						},
						show: true,
						zlevel: 2,
						splitLine: {
							show: false,
						},
						axisLine: {
							lineStyle: {
								color: '#999',
							},
						},
					},
					series: chartList,
				});
		},
		createMonitorChart(ele, title, data, xAxisData) {
			const xAxis = xAxisData
				? xAxisData.map((item, index) => `${this.dayList[index]} ${item}`)
				: this.dayList;
			echarts.init(ele).setOption({
				title: {
					text: title,
					textStyle: {
						color: '#c63535',
					},
					left: 10,
				},
				grid: {
					top: 60,
					left: 40,
					right: 40,
					bottom: 20,
				},
				animation: false,
				xAxis: {
					type: 'category',
					axisLine: {
						lineStyle: {
							color: '#c63535',
						},
					},
					data: xAxis,
				},
				yAxis: {
					type: 'value',
					splitLine: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#c63535',
						},
					},
				},
				series: [
					{
						name: 'sugar',
						type: 'line',
						data,
						areaStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
								{ offset: 0, color: '#dc7070' },
								{ offset: 1, color: '#fff' },
							]),
						},
						label: {
							show: true,
							position: 'top',
							formatter: '{c}\nmmol/L',
						},
					},
				],
			});
		},
		createSugarStackChart(data) {
			const result = data.map((item, index) => {
				return [this.dayList[index], item[1], item[2], +item[0] + +item[3], item[4]];
			});
			result.unshift([
				'type',
				'正常期',
				'调节受损期',
				'代谢紊乱（功能受损）',
				'代谢紊乱（结构受损）',
			]);
			echarts.init(this.$refs.echartStack).setOption({
				legend: {
					show: true,
				},
				color: ['#3ea40c', '#fab02a', '#f34141', '#383737'],
				grid: {
					top: 40,
					left: 50,
					right: 20,
					bottom: 20,
				},
				animation: false,
				xAxis: {
					type: 'category',
					axisLine: {
						lineStyle: {
							color: '#c63535',
						},
					},
				},
				yAxis: {
					axisLabel: {
						formatter: '{value} %',
					},
					max: 100,
					type: 'value',
					splitLine: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#c63535',
						},
					},
				},
				dataset: {
					source: result,
				},
				series: new Array(4).fill({ type: 'bar', stack: 'percent', barWidth: 30 }),
			});
		},
		createEmptyStomachSugarChart(ele, data) {
			echarts.init(ele).setOption({
				grid: {
					top: 40,
					left: 40,
					right: 40,
					bottom: 20,
				},
				animation: false,
				xAxis: {
					type: 'category',
					axisLine: {
						lineStyle: {
							color: '#c63535',
						},
					},
					data: this.dayList,
				},
				yAxis: {
					type: 'value',
					splitLine: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#c63535',
						},
					},
				},
				series: [
					{
						name: 'sugar',
						type: 'line',
						data,
						label: {
							show: true,
							position: 'top',
							formatter: '{c}\nmmol/L',
						},
						symbol: 'image://' + new URL('/src/assets/img/mq-avg.png', import.meta.url).href,
						symbolSize: 18,
					},
				],
			});
		},
		createPieChart(data) {
			echarts.init(this.$refs.pieChart).setOption({
				color: ['#3ea40c', '#fab02a', '#f34141', '#383737'],
				series: [
					{
						name: '体糖占比',
						type: 'pie',
						radius: '60%',
						data: [
							{ value: data.zc_xt, name: '正常期' },
							{ value: data.pg_xt, name: '调节受损期' },
							{ value: +data.d_xt + +data.g_xt, name: '代谢紊乱\n(功能受损)' },
							{ value: data.jg_xt, name: '代谢紊乱\n(结构受损)' },
						],
						label: {
							formatter: (params) => {
								return `${params.name}\n${params.percent} %`;
							},
							fontSize: 14,
						},
						animation: false,
					},
				],
			});
		},
		createScatterChart(ele, data, symbol) {
			echarts.init(ele).setOption({
				grid: {
					top: 40,
					left: 60,
					right: 60,
					bottom: 40,
				},
				xAxis: {
					type: 'category',
				},
				yAxis: {
					type: 'category',
				},
				series: [
					{
						symbol: 'image://' + new URL(`@/assets/img/mq-${symbol}.png`, import.meta.url).href,
						symbolSize: 24,
						type: 'scatter',
						data,
						animation: false,
					},
				],
			});
		},
		createRadarChart(ele, data) {
			echarts.init(ele).setOption({
				radar: {
					name: {
						textStyle: {
							color: '#c63535',
							fontSize: 18,
						},
					},
					axisLine: {
						lineStyle: {
							color: '#c63535',
						},
					},
					splitLine: {
						lineStyle: {
							color: '#c63535',
						},
					},
					splitArea: {
						show: false,
					},
					indicator: [
						{ name: '参比', max: 100 },
						{ name: '用药', max: 100 },
						{ name: '运动', max: 100 },
						{ name: '进店', max: 100 },
					],
				},
				series: [
					{
						type: 'radar',
						areaStyle: {},
						data: [
							{
								value: [data.cb, data.yy, data.yd, data.jd],
								name: '行为评价',
							},
						],
					},
				],
			});
		},
	},
};
</script>

<style lang="less" scoped>
.content-report {
  width: 1100px;
  margin: 0 auto;
  padding: 20px;
}
.mq-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #c63535;
  font-size: 26px;
  padding-bottom: 20px;
  border-bottom: 2px solid #c63535;
  .mq-header-left {
    font-weight: bold;
    .mq-header-img {
      height: 38px;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      color: #fff;
      background-color: #c63535;
      height: 38px;
      line-height: 38px;
      margin-left: 10px;
      vertical-align: middle;
      padding: 0 10px;
    }
  }
}
.mq-title {
  display: flex;
  justify-content: space-around;
  color: #c63535;
  font-size: 22px;
  margin: 20px 0 30px;
  .mq-title-item {
    span:last-child {
      display: inline-block;
      width: 100px;
      border-bottom: 1px solid #c63535;
      text-align: center;
      margin-left: 10px;
      padding-bottom: 5px;
    }
  }
}
.mq-sub-title {
  color: #c63535;
  font-size: 24px;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  img {
    height: 40px;
    margin-right: 10px;
  }
}
.mq-sub-title--border {
  color: #c63535;
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 5px;
  display: inline-block;
  border-bottom: 1px solid #c63535;
}
.chart-holder {
  height: 260px;
  margin: 20px 0;
}
.chart-holder-narrow {
  height: 180px;
  margin: 20px 0;
}
.mq-summary {
  display: flex;
  justify-content: space-around;
  font-size: 22px;
  margin: 20px 0;
  &-item {
    color: #c63535;
    border: 1px solid #c63535;
    border-radius: 4px;
    padding: 10px 20px;
    span:first-child {
      margin-right: 10px;
      font-size: 28px;
      font-weight: bold;
    }
  }
}
.mq-result {
  display: flex;
  margin: 30px 0;
  .mq-result-chart {
    flex: 1;
    margin-right: 20px;
  }
  .mq-result-text {
    margin-top: 20px;
    color: #c63535;
    font-size: 22px;
    padding: 10px 20px;
    border: 1px solid #ecd8d4;
    border-radius: 4px;
    p {
      line-height: 1.5;
      margin-bottom: 20px;
    }
  }
  .mq-result-body {
    text-align: center;
    flex: 1;
  }
  .mq-result-body_title {
    color: #fff;
    background-color: #c63535;
    font-size: 22px;
    font-weight: bold;
    padding: 5px 10px;
    display: inline-block;
  }
  .mq-result-body_content {
    position: relative;
    img {
      height: 600px;
    }
    .mq-content-item {
      color: #c63535;
      font-size: 24px;
      display: flex;
      align-items: center;
      position: absolute;
      &_eye {
        top: 18px;
        left: 98px;
      }
      &_shenzang {
        top: 200px;
        left: 80px;
      }
      &_shenjing {
        top: 280px;
        left: 5px;
      }
      &_foot {
        top: 550px;
        left: 30px;
      }
      &_brain {
        top: 6px;
        right: 100px;
      }
      &_heart {
        top: 150px;
        right: 85px;
      }
      &_skin {
        top: 380px;
        right: 65px;
      }
      &_xueguan {
        top: 470px;
        right: 82px;
      }
      .mq-item--line {
        height: 2px;
        background-color: #c63535;
        width: 60px;
        margin: 0 5px;
      }
    }
    .mq-item--content {
      color: #c63535;
      font-size: 20px;
      div:last-child {
        font-weight: bold;
      }
    }
  }
}
.mq-chart-flex {
  display: flex;
  align-items: center;
  margin: 20px 0;
  &-left {
    width: 180px;
  }
  &-right {
    flex: 1;
    margin-left: 30px;
    &--border {
      border: 1px solid #c63535;
      border-radius: 4px;
    }
  }
}
.mq-label-item {
  color: #c63535;
  text-align: center;
  img {
    width: 80px;
  }
}
.mq-section-title {
  margin: 20px 0;
  img {
    width: 40px;
    margin-right: 10px;
  }
}
.mq-chart-panel {
  border: 1px solid #c63535;
  border-radius: 4px;
  margin: 20px 0;
}
.mq-seven-desc {
  font-size: 22px;
  font-weight: bold;
  color: #c63535;
  margin: 30px 0;
  span {
    margin-right: 50px;
  }
}
.mq-seven-flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 30px 0;
  .mq-seven-flex-section {
    color: #c63535;
    border: 1px solid #c63535;
    border-radius: 4px;
    padding: 15px 20px;
    text-align: center;
    img {
      width: 26px;
      vertical-align: sub;
    }
  }
  .mq-seven-flex-item {
    &_one {
      font-size: 22px;
      font-weight: bold;
    }
    &_two {
      text-align: center;
      margin: 15px 0;
      span {
        font-size: 24px;
        margin: 0 10px;
      }
    }
    &_three {
      font-size: 18px;
    }
  }
}
.mq-seven-result {
  text-align: center;
  .mq-seven-result_title {
    color: #fff;
    background-color: #c63535;
    font-size: 22px;
    font-weight: bold;
    padding: 5px 40px;
    display: inline-block;
    margin: 20px 0
  }
  .mq-seven-result_result {
    font-size: 22px;
    color: #c63535;
  }
  .mq-seven-result_value {
    font-size: 26px;
    font-weight: bold;
    color: #c63535;
    margin-top: 10px;
  }
}
.mq-serven-comment {
  border: 1px solid #c63535;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 22px;
  color: #c63535;
  margin-top: 20px;
}
.mq-seven-food {
  border: 1px solid #c63535;
  border-radius: 4px;
  padding: 10px 20px;
  &-table {
    width: 100%;
    text-align: center;
    table-layout: fixed;
    tr {
      border-bottom: 1px solid #c63535;
    }
    th, td {
      color: #c63535;
      height: 38px;
    }
  }
  &-desc {
    color: #c63535;
    font-size: 22px;
    margin-top: 20px;
  }
}
</style>
