<template>
  <div class="manage-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item
          ><i class="icon-home"></i>持续体糖监测管理</Breadcrumb-item
        >
        <Breadcrumb-item>体糖报告</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="content-panel">
        <div class="content-info">
          <span>监测开始时间：<span class="content-info-value">{{ monitorInfo.jckssj }}</span></span>
          <span>已监测时间：<span class="content-info-value">{{ monitorInfo.yjcsj }}</span></span>
        </div>
        <div class="content-info">
          <RadioGroup v-model="multiDays">
            <Radio label="">15 日总结报告</Radio>
            <Radio label="1">前 7 天总结报告</Radio>
            <Radio label="2">后 7 天总结报告</Radio>
          </RadioGroup>
        </div>
        <Button type="primary" @click="generatePDF" >生成 PDF (全部)</Button>
      </div>
      <div class="content-box">
        <h2 class="content-title border-bottom">体糖报告</h2>
        <one-day
          :data="data"
          :userInfo="userInfo"
          v-for="(data, index) in singleData"
          :key="index"></one-day>
        <seven-days
          :data="sevenData"
          :behavior="sevenDataBehavior"
          :food="sevenDataFood"
          :userInfo="userInfo"
          v-if="Object.values(sevenData).length"></seven-days>
        <one-day
          :data="data"
          :userInfo="userInfo"
          v-for="(data, index) in singleDataLast"
          :key="'last' + index"></one-day>
        <seven-days
          :data="sevenDataLast"
          :behavior="{}"
          :food="{}"
          :userInfo="userInfo"
          v-if="Object.values(sevenDataLast).length"></seven-days>
        <fifteen-days
          :data="fifteenData"
          :userInfo="userInfo"
          v-if="Object.values(fifteenData).length"></fifteen-days>
        <div style="text-align: center;padding: 20px;">
          <Button type="primary" @click="saveAsPDF" v-show="singleData.length">保存 PDF</Button>
          <span v-show="!singleData.length">暂无数据</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import MemberService from '@/services/memberService';
import servicesService from '@/services/servicesService';
import reportSportsService from '@/services/reportSportsService';
import OneDay from './report-one-day.vue';
import SevenDays from './report-seven-days.vue';
import FifteenDays from './report-fifteen-days.vue';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import dayjs from 'dayjs';

export default {
	components: {
		'v-title': vTitle,
		OneDay,
		SevenDays,
		FifteenDays,
	},
	data() {
		return {
			monitorInfo: {
				days: [],
			},
			multiDays: '',
			monitorTime: '',
			userInfo: {},
			singleData: [],
			sevenData: {},
			singleDataLast: [],
			sevenDataLast: {},
			sevenDataBehavior: {},
			sevenDataFood: {},
			fifteenData: {},
		};
	},
	mounted() {
		reportSportsService.getHeadInfo({ member_id: this.$route.params.uid }).then((data) => {
			this.userInfo = data;
		});
		servicesService.MQReportTimes({ snapshotId: this.$route.params.snapshotId }).then((data) => {
			this.monitorInfo = data;
		});
	},
	methods: {
		handleClickTime(time, index) {
			this.monitorTime = { index, time };
		},
		async generatePDF() {
			this.resetData();

			const params = {
				snapshotId: this.$route.params.snapshotId,
				time_type: this.multiDays,
			};

			// 如果未选择类型就加载全部数据
			if (!this.multiDays) {
				const snapshotId = this.$route.params.snapshotId;
				this.singleData = await servicesService.MQDetailCharts({
					snapshotId,
					time_type: '1',
				});
				this.sevenData = await servicesService.MQReportSevenDays({
					snapshotId,
					type: '1',
				});
				this.singleDataLast = await servicesService.MQDetailCharts({
					snapshotId,
					time_type: '2',
				});
				this.sevenDataLast = await servicesService.MQReportSevenDays({
					snapshotId,
					type: '2',
				});
				this.sevenDataBehavior = await servicesService.MQReportSevenDaysBehavior(params);
				this.sevenDataFood = await servicesService.MQFoodTolerability(params);
				this.fifteenData = await servicesService.MQReportFifteenDays(params);
			} else {
				this.singleData = await servicesService.MQDetailCharts(params);
				// 如果不是后 7 天总结报告，需要加载全部的数据
				if (this.multiDays !== '2') {
					this.sevenDataBehavior = await servicesService.MQReportSevenDaysBehavior(params);
					this.sevenDataFood = await servicesService.MQFoodTolerability(params);
				}
				this.sevenData = await servicesService.MQReportSevenDays({
					snapshotId: this.$route.params.snapshotId,
					type: this.multiDays,
				});
			}
		},
		saveAsPDF() {
			const loading = this.$loading({
				lock: true,
				text: '正在存储中，请稍等',
				spinner: 'el-icon-loading',
			});
			setTimeout(() => {
				const printTarget = document.querySelectorAll('.content-report');
				if (printTarget.length > 0) {
					const self = this;
					const pdf = new JsPDF({ compress: true, unit: 'pt' });
					Promise.all(
						Array.from(printTarget).map((item) =>
							html2canvas(item, {
								scale: 1,
								scrollX: 0,
								scrollY: -window.scrollY,
							}),
						),
					).then((canvasList) => {
						for (let i = 0; i < canvasList.length; i++) {
							const canvas = canvasList[i];
							const contentWidth = canvas.width;
							const contentHeight = canvas.height;
							const imgWidth = 555.28;
							const imgHeight = (imgWidth / contentWidth) * contentHeight;
							const pageData = canvas.toDataURL('image/png', 1);

							if (i !== 0) pdf.addPage();
							pdf.addImage(pageData, 'png', 20, 0, imgWidth, imgHeight);

							if (i === printTarget.length - 1) {
								const time = dayjs().format('YYYYMMDD');
								const fileName = `${self.userInfo.name}${time}持续体糖监测管理.pdf`;
								// 转存 15 日报告
								if (!this.multiDays) {
									const formData = new FormData();
									formData.append('photo', pdf.output('blob'));
									formData.append('check', 1);
									formData.append('name', fileName);
									MemberService.uploadReport(formData).then((data) => {
										MemberService.addReport({
											member_id: self.$route.params.uid,
											type: 1,
											bgzl: 50,
											check_date: data.check_date,
											photo_id: data.id,
											bgms: '出具报告自动上传',
											// obj_id: 'XD-' + self.$route.params.record_id
										})
											.then(() => {
												self.$Message.success('转存成功');
											})
											.catch(() => {
												self.$Message.success('转存失败，请手动上传');
											});
									});
								}
								pdf.save(fileName);
								self.$nextTick(() => {
									loading.close();
								});
							}
						}
					});
				} else {
					this.$Message.warning('无法生成PDF，请检查报告是否存在');
				}
			}, 0);
		},
		resetData() {
			this.singleData = {};
			this.sevenData = {};
			this.singleDataLast = {};
			this.sevenDataLast = {};
			this.sevenDataBehavior = {};
			this.sevenDataFood = {};
			this.fifteenData = {};
		},
	},
};
</script>

<style lang="less" scoped>
.content-panel {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 20px;
}
.content-info {
  margin-bottom: 15px;
  &-value {
    color: #0f85f3;
    margin-right: 30px;
  }
}
.content-info-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  &-item {
    cursor: pointer;
    color: #fff;
    background-color: #bbb;
    padding: 5px 10px;
    border-radius: 4px;
    &.active {
      background-color: #fdad00;
    }
  }
}
</style>