<template>
  <div class="content-report-panel">
    <div class="content-report">
      <div class="mq-header">
        <div class="mq-header-left">
          <img src="@/assets/img/mq-title.png" class="mq-header-img" />
          <span>康一生®持续体糖监测系统</span>
        </div>
        <div class="mq-header-right">15 天体糖总结报告 ({{ monitorTime }})</div>
      </div>
      <div class="mq-title">
        <div class="mq-title-item">
          <span>姓名</span>
          <span>{{ userInfo.name }}</span>
        </div>
        <div class="mq-title-item">
          <span>性别</span>
          <span>{{ userInfo.sex_show }}</span>
        </div>
        <div class="mq-title-item">
          <span>年龄</span>
          <span>{{ userInfo.age }}</span>
        </div>
        <div class="mq-title-item">
          <span>身高</span>
          <span>{{ userInfo.sg }}</span>
        </div>
        <div class="mq-title-item">
          <span>体重</span>
          <span>{{ userInfo.tz }}</span>
        </div>
        <div class="mq-title-item">
          <span>BMI</span>
          <span>{{ userInfo.bmi }}</span>
        </div>
      </div>
      <table class="mq-summary">
        <tbody>
          <tr>
            <td>
              <div class="mq-summary-value">{{sugarSummary.ttpjz}}</div>
              <div class="mq-summary-label">mmol/L</div>
              <div class="mq-summary-label">体糖平均值</div>
            </td>
            <td>
              <div class="mq-summary-value">{{sugarSummary.ttbdzg}}</div>
              <div class="mq-summary-label">mmol/L</div>
              <div class="mq-summary-label">体糖波动最高</div>
            </td>
            <td>
              <div class="mq-summary-value">{{sugarSummary.ttzgz}}</div>
              <div class="mq-summary-label">mmol/L</div>
              <div class="mq-summary-label">体糖最高值（{{sugarSummary.ttzdz_rq}}）</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="mq-summary-value">{{sugarSummary.TIR_zb}} %</div>
              <div class="mq-summary-label">TIR</div>
              <div class="mq-summary-label">目标范围内时间占比</div>
            </td>
            <td>
              <div class="mq-summary-value">{{sugarSummary.ttbdzd}}</div>
              <div class="mq-summary-label">mmol/L</div>
              <div class="mq-summary-label">体糖波动最低</div>
            </td>
            <td>
              <div class="mq-summary-value">{{sugarSummary.ttzdz}}</div>
              <div class="mq-summary-label">mmol/L</div>
              <div class="mq-summary-label">体糖最低值（{{sugarSummary.ttzdz_rq}}）</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mq-sub-title">
        <img src="@/assets/img/mq-sub-title.png" class="mq-sub-img" />
        24 时动态体糖曲线
      </div>
      <div class="chart-holder" ref="echart"></div>
      <div class="mq-sub-title">
        <img src="@/assets/img/mq-title-logo.png" class="mq-sub-img" />
        体糖时间占比堆积图
      </div>
      <div class="chart-holder" ref="echartStack"></div>
    </div>
    <div class="content-report">
      <div class="mq-header">
        <div class="mq-header-left">
          <img src="@/assets/img/mq-title.png" class="mq-header-img" />
          <span>康一生®持续体糖监测系统</span>
        </div>
        <div class="mq-header-right">前七天 VS 后七天 ({{ monitorTime }})</div>
      </div>
      <div class="mq-title">
        <div class="mq-title-item">
          <span>姓名</span>
          <span>{{ userInfo.name }}</span>
        </div>
        <div class="mq-title-item">
          <span>性别</span>
          <span>{{ userInfo.sex_show }}</span>
        </div>
        <div class="mq-title-item">
          <span>年龄</span>
          <span>{{ userInfo.age }}</span>
        </div>
        <div class="mq-title-item">
          <span>身高</span>
          <span>{{ userInfo.sg }}</span>
        </div>
        <div class="mq-title-item">
          <span>体重</span>
          <span>{{ userInfo.tz }}</span>
        </div>
        <div class="mq-title-item">
          <span>BMI</span>
          <span>{{ userInfo.bmi }}</span>
        </div>
      </div>
            <div class="mq-result">
        <div class="mq-result-chart">
          <div class="mq-result-body_title">前七日体糖分布</div>
          <div style="height: 320px;margin-top:20px;" ref="pieChartFirst"></div>
          <div class="mq-result-text">
            <p>{{ xtfwfbFirst.jgms1 }}</p>
            <p>{{ xtfwfbFirst.jgms2 }}</p>
          </div>
        </div>
        <div class="mq-result-body">
          <div class="mq-result-body_title">代谢性慢性疾病风险</div>
          <div class="mq-result-body_content">
            <img src="@/assets/img/mq-body.jpg" />
            <div class="mq-content-item mq-content-item_eye">
              <div class="mq-item--content">
                <div>眼部风险</div>
                <div>{{ bodyDataFirst.yb }} %</div>
              </div>
              <div class="mq-item--line"></div>
            </div>
            <div class="mq-content-item mq-content-item_shenzang">
              <div class="mq-item--content">
                <div>肾脏风险</div>
                <div>{{ bodyDataFirst.sz }} %</div>
              </div>
              <div class="mq-item--line"></div>
            </div>
            <div class="mq-content-item mq-content-item_shenjing">
              <div class="mq-item--content">
                <div>神经风险</div>
                <div>{{ bodyDataFirst.sj }} %</div>
              </div>
              <div class="mq-item--line"></div>
            </div>
            <div class="mq-content-item mq-content-item_foot">
              <div class="mq-item--content">
                <div>糖尿病足风险</div>
                <div>{{ bodyDataFirst.tnbz }} %</div>
              </div>
              <div class="mq-item--line"></div>
            </div>
            <div class="mq-content-item mq-content-item_brain">
              <div class="mq-item--line"></div>
              <div class="mq-item--content">
                <div>脑部风险</div>
                <div>{{ bodyDataFirst.nb }} %</div>
              </div>
            </div>
            <div class="mq-content-item mq-content-item_heart">
              <div class="mq-item--line"></div>
              <div class="mq-item--content">
                <div>心脏风险</div>
                <div>{{ bodyDataFirst.xz }} %</div>
              </div>
            </div>
            <div class="mq-content-item mq-content-item_skin">
              <div class="mq-item--line"></div>
              <div class="mq-item--content">
                <div>皮肤风险</div>
                <div>{{ bodyDataFirst.pf }} %</div>
              </div>
            </div>
            <div class="mq-content-item mq-content-item_xueguan">
              <div class="mq-item--line"></div>
              <div class="mq-item--content">
                <div>血管风险</div>
                <div>{{ bodyDataFirst.xg }} %</div>
              </div>
            </div>
          </div>
        </div>
      </div>
            <div class="mq-result">
        <div class="mq-result-chart">
          <div class="mq-result-body_title">后七日体糖分布</div>
          <div style="height: 320px;margin-top:20px;" ref="pieChartLast"></div>
          <div class="mq-result-text">
            <p>{{ xtfwfbLast.jgms1 }}</p>
            <p>{{ xtfwfbLast.jgms2 }}</p>
          </div>
        </div>
        <div class="mq-result-body">
          <div class="mq-result-body_title">代谢性慢性疾病风险</div>
          <div class="mq-result-body_content">
            <img src="@/assets/img/mq-body.jpg" />
            <div class="mq-content-item mq-content-item_eye">
              <div class="mq-item--content">
                <div>眼部风险</div>
                <div>{{ bodyDataLast.yb }} %</div>
              </div>
              <div class="mq-item--line"></div>
            </div>
            <div class="mq-content-item mq-content-item_shenzang">
              <div class="mq-item--content">
                <div>肾脏风险</div>
                <div>{{ bodyDataLast.sz }} %</div>
              </div>
              <div class="mq-item--line"></div>
            </div>
            <div class="mq-content-item mq-content-item_shenjing">
              <div class="mq-item--content">
                <div>神经风险</div>
                <div>{{ bodyDataLast.sj }} %</div>
              </div>
              <div class="mq-item--line"></div>
            </div>
            <div class="mq-content-item mq-content-item_foot">
              <div class="mq-item--content">
                <div>糖尿病足风险</div>
                <div>{{ bodyDataLast.tnbz }} %</div>
              </div>
              <div class="mq-item--line"></div>
            </div>
            <div class="mq-content-item mq-content-item_brain">
              <div class="mq-item--line"></div>
              <div class="mq-item--content">
                <div>脑部风险</div>
                <div>{{ bodyDataLast.nb }} %</div>
              </div>
            </div>
            <div class="mq-content-item mq-content-item_heart">
              <div class="mq-item--line"></div>
              <div class="mq-item--content">
                <div>心脏风险</div>
                <div>{{ bodyDataLast.xz }} %</div>
              </div>
            </div>
            <div class="mq-content-item mq-content-item_skin">
              <div class="mq-item--line"></div>
              <div class="mq-item--content">
                <div>皮肤风险</div>
                <div>{{ bodyDataLast.pf }} %</div>
              </div>
            </div>
            <div class="mq-content-item mq-content-item_xueguan">
              <div class="mq-item--line"></div>
              <div class="mq-item--content">
                <div>血管风险</div>
                <div>{{ bodyDataLast.xg }} %</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-report">
      <div class="mq-header">
        <div class="mq-header-left">
          <img src="@/assets/img/mq-title.png" class="mq-header-img" />
          <span>康一生®持续体糖监测系统</span>
        </div>
        <div class="mq-header-right">前七天 VS 后七天 ({{ monitorTime }})</div>
      </div>
      <div class="mq-title">
        <div class="mq-title-item">
          <span>姓名</span>
          <span>{{ userInfo.name }}</span>
        </div>
        <div class="mq-title-item">
          <span>性别</span>
          <span>{{ userInfo.sex_show }}</span>
        </div>
        <div class="mq-title-item">
          <span>年龄</span>
          <span>{{ userInfo.age }}</span>
        </div>
        <div class="mq-title-item">
          <span>身高</span>
          <span>{{ userInfo.sg }}</span>
        </div>
        <div class="mq-title-item">
          <span>体重</span>
          <span>{{ userInfo.tz }}</span>
        </div>
        <div class="mq-title-item">
          <span>BMI</span>
          <span>{{ userInfo.bmi }}</span>
        </div>
      </div>
      <table class="mq-table">
        <tbody>
          <tr>
            <th style="width: 20%;"></th>
            <th>前七天</th>
            <th>后七天</th>
            <th>差值</th>
          </tr>
          <tr>
            <td>
              <div>七日平均体糖</div>
              <div>(mmol/L)</div>
            </td>
            <td v-for="(item, index) in sugarTable[0]" :key="index">{{item}}</td>
          </tr>
          <tr>
            <td>
              <div>七日最高体糖</div>
              <div>(mmol/L)</div>
            </td>
            <td v-for="(item, index) in sugarTable[1]" :key="index">{{item}}</td>
          </tr>
          <tr>
            <td>
              <div>七日最低体糖</div>
              <div>(mmol/L)</div>
            </td>
            <td v-for="(item, index) in sugarTable[2]" :key="index">{{item}}</td>
          </tr>
          <tr>
            <td>
              <div>七日体糖波动</div>
              <div>(mmol/L)</div>
            </td>
            <td v-for="(item, index) in sugarTable[3]" :key="index">{{item}}</td>
          </tr>
          <tr>
            <td>
              <div>N0-6</div>
              <div>体糖平均值</div>
            </td>
            <td v-for="(item, index) in sugarTable[4]" :key="index">{{item}}</td>
          </tr>
          <tr>
            <td>
              <div>E6-12</div>
              <div>体糖平均值</div>
            </td>
            <td v-for="(item, index) in sugarTable[5]" :key="index">{{item}}</td>
          </tr>
          <tr>
            <td>
              <div>S12-18</div>
              <div>体糖平均值</div>
            </td>
            <td v-for="(item, index) in sugarTable[6]" :key="index">{{item}}</td>
          </tr>
          <tr>
            <td>
              <div>W18-24</div>
              <div>体糖平均值</div>
            </td>
            <td v-for="(item, index) in sugarTable[7]" :key="index">{{item}}</td>
          </tr>
          <tr>
            <td>
              <div>(0，4.4)</div>
              <div>(mmol/L)</div>
            </td>
            <td v-for="(item, index) in sugarTable[8]" :key="index">{{item}}</td>
          </tr>
          <tr>
            <td>
              <div>[4.4，6.1]</div>
              <div>(mmol/L)</div>
            </td>
            <td v-for="(item, index) in sugarTable[9]" :key="index">{{item}}</td>
          </tr>
          <tr>
            <td>
              <div>(6.1，7.8)</div>
              <div>(mmol/L)</div>
            </td>
            <td v-for="(item, index) in sugarTable[10]" :key="index">{{item}}</td>
          </tr>
          <tr>
            <td>
              <div>[7.8，11.1]</div>
              <div>(mmol/L)</div>
            </td>
            <td v-for="(item, index) in sugarTable[11]" :key="index">{{item}}</td>
          </tr>
          <tr>
            <td>
              <div>(11.1，∞]</div>
              <div>(mmol/L)</div>
            </td>
            <td v-for="(item, index) in sugarTable[12]" :key="index">{{item}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>  
</template>

<script type="text/ecmascript-6">
import echarts from 'echarts';

export default {
	props: {
		userInfo: {
			default: () => {},
		},
		data: {
			default: () => {},
		},
	},
	data() {
		return {
			monitorTime: '',
			dayList: [],
			sugarSummary: {},
			xtfwfbFirst: {},
			xtfwfbLast: {},
			bodyDataFirst: {},
			bodyDataLast: {},
			sugarTable: [],
		};
	},
	mounted() {
		this.dayList = this.data.day_list;
		this.monitorTime = `${this.dayList[0]}至${this.dayList[this.dayList.length - 1]}`;
		this.sugarSummary = this.data.part1;
		this.sugarTable = this.data.part6;
		this.xtfwfbFirst = this.data.part4.xtfwfb; // 前七天体糖分布
		this.xtfwfbLast = this.data.part5.xtfwfb; // 后七天体糖分布
		this.bodyDataFirst = this.data.part4.bfzfxfx; // 前七天身体数据
		this.bodyDataLast = this.data.part5.bfzfxfx; // 后七天身体数据
		this.createLineChart(this.data.part2);
		const refs = this.$refs;
		this.createPieChart(refs.pieChartFirst, this.xtfwfbFirst.fbt);
		this.createPieChart(refs.pieChartLast, this.xtfwfbLast.fbt);
		this.createSugarStackChart(this.data.part3);
	},
	methods: {
		createLineChart(data) {
			const chartList = data.map((d, i) => {
				const chartData = [];
				const markPointData = [];
				for (let i = 0, len = d.length; i < len; i++) {
					const item = d[i];
					const recordDate = data[0][i].recordDate.substr(11);
					if (item.machineBloodSugar > 0) {
						chartData.push([recordDate, item.machineBloodSugar]);
					}
					if (item.eventList.length > 0) {
						for (let j = 0, l = item.eventList.length; j < l; j++) {
							const eventType = item.eventList[j].eventType;
							if (eventType === 1) {
								const value = [recordDate, item.machineBloodSugar];
								markPointData.push({
									coord: value,
									symbol: 'image://' + new URL('/src/assets/img/canbi.png', import.meta.url).href,
									symbolSize: 18,
								});
							} else if (eventType === 2) {
								const value = [recordDate, item.machineBloodSugar];
								markPointData.push({
									coord: value,
									symbol: 'image://' + new URL('/src/assets/img/yinshi.png', import.meta.url).href,
									symbolSize: 18,
								});
							} else if (eventType === 3) {
								const value = [recordDate, item.machineBloodSugar];
								markPointData.push({
									coord: value,
									symbol: 'image://' + new URL('/src/assets/img/yongyao.png', import.meta.url).href,
									symbolSize: 18,
								});
							} else if (eventType === 5) {
								const value = [recordDate, item.machineBloodSugar];
								markPointData.push({
									coord: value,
									symbol:
										'image://' + new URL('/src/assets/img/duanlian.png', import.meta.url).href,
									symbolSize: 18,
								});
							}
						}
					}
				}
				return {
					name: this.dayList[i],
					type: 'line',
					data: chartData,
					symbol: 'none',
					markPoint: {
						symbolSize: 30,
						data: markPointData,
					},
				};
			});
			(chartList[0].markArea = {
				data: [
					[
						{
							yAxis: 0,
							itemStyle: { color: '#fdcbcb' },
						},
						{
							yAxis: 4.4,
						},
					],
					[
						{
							yAxis: 4.4,
							itemStyle: { color: '#baeeb8' },
						},
						{
							yAxis: 6.1,
						},
					],
					[
						{
							yAxis: 6.1,
							itemStyle: { color: '#fdca9d' },
						},
						{
							yAxis: 7.8,
						},
					],
					[
						{
							yAxis: 7.8,
							itemStyle: { color: '#ff9e9e' },
						},
						{
							yAxis: 11.1,
						},
					],
					[
						{
							yAxis: 11.1,
							itemStyle: { color: '#b2b2b2' },
						},
						{
							yAxis: Infinity,
						},
					],
				],
				silent: true,
			}),
				echarts.init(this.$refs.echart).setOption({
					legend: {
						show: true,
					},
					grid: {
						top: 75,
						left: 25,
						right: 25,
						bottom: 40,
					},
					animation: false,
					xAxis: {
						type: 'category',
						axisTick: {
							show: false,
						},
						axisLine: {
							lineStyle: {
								color: '#999',
							},
						},
						splitLine: false,
					},
					yAxis: {
						name: 'mmol/L',
						type: 'value',
						axisTick: {
							show: true,
							interval: 5,
						},
						minorTick: {
							show: true,
							splitNumber: 2,
						},
						show: true,
						zlevel: 2,
						splitLine: {
							show: false,
						},
						axisLine: {
							lineStyle: {
								color: '#999',
							},
						},
					},
					series: chartList,
				});
		},
		createSugarStackChart(data) {
			const result = data.map((item, index) => {
				return [this.dayList[index], item[1], item[2], +item[0] + +item[3], item[4]];
			});
			result.unshift([
				'type',
				'正常期',
				'调节受损期',
				'代谢紊乱（功能受损）',
				'代谢紊乱（结构受损）',
			]);
			echarts.init(this.$refs.echartStack).setOption({
				legend: {
					show: true,
				},
				color: ['#3ea40c', '#fab02a', '#f34141', '#383737'],
				grid: {
					top: 40,
					left: 50,
					right: 20,
					bottom: 20,
				},
				animation: false,
				xAxis: {
					type: 'category',
					axisLine: {
						lineStyle: {
							color: '#c63535',
						},
					},
				},
				yAxis: {
					axisLabel: {
						formatter: '{value} %',
					},
					max: 100,
					type: 'value',
					splitLine: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#c63535',
						},
					},
				},
				dataset: {
					source: result,
				},
				series: new Array(4).fill({ type: 'bar', stack: 'percent', barWidth: 30 }),
			});
		},
		createPieChart(ele, data) {
			echarts.init(ele).setOption({
				color: ['#3ea40c', '#fab02a', '#f34141', '#383737'],
				series: [
					{
						name: '体糖占比',
						type: 'pie',
						radius: '60%',
						data: [
							{ value: data.zc_xt, name: '正常期' },
							{ value: data.pg_xt, name: '调节受损期' },
							{ value: +data.d_xt + +data.g_xt, name: '代谢紊乱\n(功能受损)' },
							{ value: data.jg_xt, name: '代谢紊乱\n(结构受损)' },
						],
						label: {
							formatter: (params) => {
								return `${params.name}\n${params.percent} %`;
							},
							fontSize: 14,
						},
						animation: false,
					},
				],
			});
		},
	},
};
</script>

<style lang="less" scoped>
.content-report {
  width: 1100px;
  margin: 0 auto;
  padding: 20px;
}
.mq-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #c63535;
  font-size: 26px;
  padding-bottom: 20px;
  border-bottom: 2px solid #c63535;
  .mq-header-left {
    font-weight: bold;
    .mq-header-img {
      height: 38px;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      color: #fff;
      background-color: #c63535;
      height: 38px;
      line-height: 38px;
      margin-left: 10px;
      vertical-align: middle;
      padding: 0 10px;
    }
  }
}
.mq-title {
  display: flex;
  justify-content: space-around;
  color: #c63535;
  font-size: 22px;
  margin: 20px 0 30px;
  .mq-title-item {
    span:last-child {
      display: inline-block;
      width: 100px;
      border-bottom: 1px solid #c63535;
      text-align: center;
      margin-left: 10px;
      padding-bottom: 5px;
    }
  }
}
.mq-sub-title {
  color: #c63535;
  font-size: 24px;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  img {
    height: 40px;
    margin-right: 10px;
  }
}
.mq-summary {
  width: 80%;
  margin: 30px auto;
  td {
    border: 1px solid #c63535;
    color: #c63535;
    text-align: center;
    padding: 10px;
  }
  .mq-summary-value {
    font-size: 30px;
    font-weight: 700;
  }
  .mq-summary-label {
    font-size: 20px;
    margin: 10px 0;
  }
}
.chart-holder {
  height: 300px;
  margin: 20px 0;
}
.mq-result {
  display: flex;
  margin: 30px 0;
  .mq-result-chart {
    flex: 1;
    margin-right: 20px;
  }
  .mq-result-text {
    margin-top: 20px;
    color: #c63535;
    font-size: 22px;
    padding: 10px 20px;
    border: 1px solid #ecd8d4;
    border-radius: 4px;
    p {
      line-height: 1.5;
      margin-bottom: 20px;
    }
  }
  .mq-result-body {
    text-align: center;
    flex: 1;
  }
  .mq-result-body_title {
    color: #fff;
    background-color: #c63535;
    font-size: 22px;
    font-weight: bold;
    padding: 5px 10px;
    display: inline-block;
  }
  .mq-result-body_content {
    position: relative;
    img {
      height: 600px;
    }
    .mq-content-item {
      color: #c63535;
      font-size: 24px;
      display: flex;
      align-items: center;
      position: absolute;
      &_eye {
        top: 18px;
        left: 98px;
      }
      &_shenzang {
        top: 200px;
        left: 80px;
      }
      &_shenjing {
        top: 280px;
        left: 5px;
      }
      &_foot {
        top: 550px;
        left: 30px;
      }
      &_brain {
        top: 6px;
        right: 100px;
      }
      &_heart {
        top: 150px;
        right: 85px;
      }
      &_skin {
        top: 380px;
        right: 65px;
      }
      &_xueguan {
        top: 470px;
        right: 82px;
      }
      .mq-item--line {
        height: 2px;
        background-color: #c63535;
        width: 60px;
        margin: 0 5px;
      }
    }
    .mq-item--content {
      color: #c63535;
      font-size: 20px;
      div:last-child {
        font-weight: bold;
      }
    }
  }
}
.mq-table {
  margin: 30px 0;
  width: 100%;
  text-align: center;
  table-layout: fixed;
  tr {
    border-bottom: 1px solid #c63535;
  }
  th {
    font-size: 20px;
    background-color: #c63535;
    padding: 10px 0;
    color: #fff;
  }
  td {
    color: #c63535;
    padding: 10px 0;
    font-size: 20px;
    &:not(:first-child) {
      font-size: 24px;
    }
  }
}
</style>
